import React from 'react';
import PropType from 'prop-types';
import { navigate } from 'gatsby';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Layout from '../components/layout/layout';
import logo from '../images/404.png';

const PageNotfoundMain = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '25px',
  marginBottom: '25px',
  '& .pageNotFound': {
    background: '#F0F0F0 0% 0%',
    boxShadow: '1px 1px 3px #00000029',
    border: '1px solid #DBDBDB',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '80vw',
    height: 'calc(111vh - 184px)',
    //padding: '110px 150px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  '& Button': {
    marginTop: '20px'
  }
}));

function UnAuthorizedPage({ location }) {
  const goToHome = event => {
    event.preventDefault();
    navigate('/');
  };
  return (
    <Layout location={location} pageName='404' is404>
      <Grid
        container
        direction={{ xs: 'row' }}
        justifyContent='center'
        alignItems='center'
        boxSizing='inherit'
      >
        <PageNotfoundMain>
          <Box className='pageNotFound' p={{ xs: '20px', md: '0px 0px' }}>
            <Grid
              container
              direction={{ xs: 'row' }}
              justifyContent='center'
              alignItems='center'
              spacing={2}
            >
              <Grid item xs={12} sm={6}>
                <img src={logo} alt='notfound' width='75%' />
              </Grid>
              <Grid xs={12} sm={6}>
                <Typography align='left' gutterBottom variant='h2' component='div'>
                  OOPS !! NOT AUTHORIZED
                </Typography>
                <Typography align='left' gutterBottom variant='body2' component='div'>
                  Dear User, you are currently not authorized to view this site.
                  {' '}
                  <br />
                  {' '}
                  Please contact site administrator.
                </Typography>
                <Button size='small' variant='contained' onClick={goToHome}>BACK TO HOMEPAGE</Button>
              </Grid>
            </Grid>
          </Box>
        </PageNotfoundMain>
      </Grid>
    </Layout>
  );
}

UnAuthorizedPage.propTypes = {
  location: PropType.shape.isRequired
};

export default UnAuthorizedPage;
